import { createApp } from "vue";
import { VueWindowSizePlugin } from "vue-window-size/option-api";
import { OhVueIcon, addIcons } from "oh-vue-icons";
import {
  FaCcStripe,
  IoLogoJavascript,
  ViFileTypeTypescriptOfficial,
  IoLogoCss3,
  IoLogoHtml5,
  CoAmazonAws,
  CoEleventy,
  ViFileTypeTailwind,
  IoLogoVue,
  ViFileTypeDartlang,
  ViFileTypeGoLightblue,
  CoPhp,
  CoJava,
  ViFileTypeMysql,
  ViFileTypePython,
  CoGoogleCloud,
  ViFileTypeAzure,
  CoReact,
  IoBusinessSharp,
} from "oh-vue-icons/icons";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";

addIcons(
  FaCcStripe,
  IoLogoJavascript,
  ViFileTypeTypescriptOfficial,
  IoLogoCss3,
  IoLogoHtml5,
  CoAmazonAws,
  CoEleventy,
  ViFileTypeTailwind,
  IoLogoVue,
  ViFileTypeDartlang,
  ViFileTypeGoLightblue,
  CoPhp,
  CoJava,
  ViFileTypeMysql,
  ViFileTypePython,
  CoGoogleCloud,
  ViFileTypeAzure,
  CoReact,
  IoBusinessSharp,
);

const app = createApp(App);

app.use(router);
app.use(VueWindowSizePlugin);

// treat all tags starting with 'ion-' as custom elements
app.config.compilerOptions.isCustomElement = (tag) => tag.startsWith("ion-");
app.component("v-icon", OhVueIcon);

app.mount("#app");

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col space-y-10 my-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PortfolioCard = _resolveComponent("PortfolioCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PortfolioCard, {
      title: 'Solarus.club',
      site: 'https://solarus.club',
      description: 'A custom web application with fullstack capabilities and payment processing.',
      tech: ['io-logo-vue', 'vi-file-type-typescript-official', 'io-logo-html5', 'io-logo-css3', 'vi-file-type-tailwind', 'fa-cc-stripe', 'co-amazon-aws']
    }, null, 8, ["title", "site", "description"]),
    _createVNode(_component_PortfolioCard, {
      title: 'Grass in the Road',
      site: 'https://grassintheroad.com',
      description: 'An interactive custom intinerary website.',
      tech: ['co-eleventy', 'io-logo-html5', 'io-logo-css3', 'io-logo-javascript', 'vi-file-type-tailwind']
    }, null, 8, ["site", "description"]),
    _createVNode(_component_PortfolioCard, {
      title: 'Masjid Muhammad',
      site: 'https://mmuhammad.org',
      description: 'A local islamic resource website.',
      tech: ['co-eleventy', 'io-logo-html5', 'io-logo-css3', 'io-logo-javascript', 'vi-file-type-tailwind']
    }, null, 8, ["site", "description"]),
    _createVNode(_component_PortfolioCard, {
      title: 'Chattanooga Islamic Outreach',
      site: 'https://chattio.org',
      description: 'A local islamic resource website.',
      tech: ['co-eleventy', 'io-logo-html5', 'io-logo-css3', 'io-logo-javascript', 'vi-file-type-tailwind']
    }, null, 8, ["site", "description"])
  ]))
}

import { defineComponent } from "vue";
import { OhVueIcon } from "oh-vue-icons";

export default defineComponent({
  name: "PortfolioCard",
  components: {
    "v-icon": OhVueIcon,
  },
  setup() {
    return {};
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    employer: {
      type: String,
      required: true,
    },
  },
});

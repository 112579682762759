import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col space-y-10 my-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResumeCard = _resolveComponent("ResumeCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ResumeCard, {
      date: '2022 - Present',
      title: 'Nexus Lab: Front-end Software Engineer (Current)',
      employer: 'University of Tennessee at Chattanooga',
      description: 'Develop and maintain an NSA-funded application, which utilizes a React.js/Next.js frontend and a Python/FastAPI backend. Daily duties include developing new features, fixing bugs, and improving the overall user experience.'
    }, null, 8, ["title", "description"]),
    _createVNode(_component_ResumeCard, {
      date: '2021 - 2021',
      title: 'Fullstack Software Engineer Contractor',
      employer: 'Grass in the Road',
      description: 'Co-created and designed an interactive travel itinerary application, which is primarily built on TailwindCSS and static HTML.'
    }, null, 8, ["description"]),
    _createVNode(_component_ResumeCard, {
      date: '2021 - 2021',
      title: 'Voluntary Fullstack Software Engineer',
      employer: 'Masjid Muhammad',
      description: 'Co-created and designed an interactive travel itinerary application, which is primarily built on TailwindCSS and static HTML.'
    }, null, 8, ["description"]),
    _createVNode(_component_ResumeCard, {
      date: '2021 - 2021',
      title: 'Voluntary Fullstack Software Engineer',
      employer: 'Chattanooga Islamic Outreach',
      description: 'Co-created and designed an interactive travel itinerary application, which is primarily built on TailwindCSS and static HTML and utilizes Prismic.io for content management.'
    }, null, 8, ["description"]),
    _createVNode(_component_ResumeCard, {
      date: '2019 - 2021',
      title: 'Fullstack Software Engineer',
      employer: 'MyVideoVault, LLC.',
      description: 'Designed, developed and maintained a video streaming/sharing platform to help older adults stay connected with their loved ones. The initial platform was built on a Flutter/Dart frontend and an Express.js/Javascript backend.'
    }, null, 8, ["employer", "description"])
  ]))
}

// @ts-nocheck
import { defineComponent, computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useWindowSize } from "vue-window-size";
import Navigation from "@/components/Navigation.vue";
import Cursor from "@/components/Cursor.vue";
import MobileNavigation from "./components/MobileNavigation.vue";
import HomeView from "./views/HomeView.vue";
import PortfolioView from "./views/PortfolioView.vue";
import ResumeView from "./views/ResumeView.vue";
import AboutView from "./views/AboutView.vue";
import ContactView from "./views/ContactView.vue";
import "./css/root.css";

const isMobile = ref(false);
const currentpage = ref("home");

const handleRoute = (route) => {
  currentpage.value = route;
};

export default defineComponent({
  name: "App",
  components: {
    Navigation,
    Cursor,
    MobileNavigation,
    HomeView,
    PortfolioView,
    ResumeView,
    AboutView,
    ContactView,
  },
  setup() {
    const { width, height } = useWindowSize();
    const currentRoute = computed(() => useRoute().name);

    watch(currentpage, (page, prevPage) => {
      window.location.hash = page;

      // Set canvas classes animated and fadeout
      if (currentpage.value !== "home" && currentpage.value !== "") {
        document.querySelector("canvas").classList.add("animated-fadeout", "fadeOut");
      } else {
        document.querySelector("canvas").classList.remove("animated-fadeout", "fadeOut");
      }
    });

    // set initial value of currentpage based on hash
    if (window.location.hash !== "") {
      currentpage.value = window.location.hash.replace("#", "");
    }

    if (window.innerWidth < 768) {
      isMobile.value = true;
    } else {
      isMobile.value = false;
    }

    return {
      windowWidth: width,
      windowHeight: height,
      currentRoute,
      handleRoute,
      currentpage,
      isMobile,
    };
  },
  mounted() {
    const nextParticle = new NextParticle({
      image: document.querySelector("#logo"),
      width: window.innerWidth > 639 ? 1100 : window.innerWidth + 400,
      height: window.innerWidth > 639 ? window.innerHeight + 100 : window.innerHeight,
      particleGap: 2,
      initPosition: "none",
      initDirection: "none",
      maxWidth: window.innerWidth > 639 ? 2000 : 2000,
      maxHeight: window.innerWidth > 639 ? 2000 : 2000,
      imageWidth: 2000,
      imageHeight: 2000,
    });

    window.nextParticle = nextParticle;

    window.onresize = function () {
      if (window.innerWidth > 639) {
        window.nextParticle.maxHeight = 2000;
        window.nextParticle.maxWidth = 2000;

        nextParticle.imageHeight = 1200;
        nextParticle.imageWidth = 1200;

        nextParticle.width = 1100;
        nextParticle.height = window.innerHeight + 100;

        nextParticle.start();
      }

      if (window.innerWidth < 640) {
        nextParticle.width = window.innerWidth + 400;
        nextParticle.height = window.innerHeight;

        nextParticle.maxHeight = 2000;
        nextParticle.maxWidth = 2000;

        nextParticle.start();
      }

      if (window.innerWidth < 768) {
        canvas.style.left = "-60px";
      } else {
        canvas.style.left = "unset";
      }

      if (window.innerWidth > 768) {
        canvas.style.right = "1rem";
      } else {
        canvas.style.right = "unset";
      }

      if (window.innerWidth < 768) {
        isMobile.value = true;
      } else {
        isMobile.value = false;
      }
    };

    window.onload = () => {
      window.nextParticle.start();
      window.canvas = nextParticle.canvas;
      canvas.style.overflow = "hidden";
      canvas.style.position = "fixed";
      canvas.style.top = "0";
      canvas.style.zIndex = "10";

      if (window.location.hash !== "#home" && window.location.hash !== "") {
        document.querySelector("canvas").classList.add("animated-fadeout", "fadeOut");
      } else {
        document.querySelector("canvas").classList.remove("animated-fadeout", "fadeOut");
      }

      if (window.innerWidth < 768) {
        canvas.style.left = "-100px";
      } else {
        canvas.style.left = "unset";
      }

      if (window.innerWidth > 768) {
        canvas.style.right = "1rem";
      } else {
        canvas.style.right = "unset";
      }
    };
  },
});


import { defineComponent } from "vue";
import { OhVueIcon } from "oh-vue-icons";

export default defineComponent({
  components: {
    "v-icon": OhVueIcon,
  },
  setup() {
    return {};
  },
});

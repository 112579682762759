
import { defineComponent, PropType } from "vue";
import { OhVueIcon } from "oh-vue-icons";

// AWS orange
const logoColors: { [id: string]: string; } = {
  "io-logo-vue": "#42b883",
  "io-logo-html5": "#e34f26",
  "io-logo-css3": "#2965f1",
  "io-logo-javascript": "#f7df1e",
  "vi-file-type-typescript-official": "#3178c6",
  "vi-file-type-tailwind": "#06b6d4",
  "fa-cc-stripe": "#6772e5",
  "co-amazon-aws": "#fff",
  "co-eleventy": "#fff",
};

export default defineComponent({
  name: "PortfolioCard",
  components: {
    "v-icon": OhVueIcon,
  },
  setup() {
    return {
      logoColors,
    };
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    site: {
      type: String,
      required: true,
    },
    tech: {
      type: Array as PropType<string[]>,
      required: false,
    },
  },
});


import { defineComponent } from "vue";

export default defineComponent({
  name: "Navigation",
  emits: ["handleRouteChange"],
  methods: {
    changeRoute(page: string) {
      this.$emit('handleRouteChange', page);
    },
  },
});

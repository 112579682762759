
import { defineComponent } from "vue";
import ResumeCard from "@/components/Resume/ResumeCard.vue";

export default defineComponent({
  components: { ResumeCard },
  setup() {
    return {};
  },
});

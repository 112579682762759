import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "flex lg:flex-row flex-col mb-10" }
const _hoisted_3 = {
  class: "thumbnail-container rounded cursor-none w-[15rem]",
  title: "Tribute"
}
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "thumbnail" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "flex flex-col space-y-4 pt-4 lg:ml-6" }
const _hoisted_8 = { class: "text-lg break-keep font-semibold" }
const _hoisted_9 = { class: "text-base break-keep font-normal" }
const _hoisted_10 = { class: "flex flex-row space-x-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("a", {
          href: _ctx.site,
          target: "_blank"
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("iframe", {
              src: _ctx.site,
              frameborder: "0",
              onload: "this.style.opacity = 1"
            }, null, 8, _hoisted_6)
          ])
        ], 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.description), 1),
        _createElementVNode("div", _hoisted_10, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tech, (t) => {
            return (_openBlock(), _createElementBlock("div", { key: t }, [
              _createVNode(_component_v_icon, {
                name: t,
                scale: 1.5,
                fill: _ctx.logoColors[t]
              }, null, 8, ["name", "scale", "fill"])
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}
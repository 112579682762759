
import { defineComponent } from "vue";

export default defineComponent({
  name: "Cursor",
  mounted() {
    /**
     * Mouse determinations
     */

    // eslint-disable-next-line no-undef
    // @ts-ignore
    const { TweenMax } = globalThis;

    const bigBall = document.querySelector(".cursor__ball--big");
    const smallBall = document.querySelector(".cursor__ball--small");

    // Move the cursor
    function onMouseMove(e: any) {
      // eslint-disable-next-line no-use-before-define
      TweenMax.to(bigBall, 0.4, {
        x: e.pageX - 15,
        y: e.pageY - 15,
      });
      TweenMax.to(smallBall, 0.1, {
        x: e.pageX - 5,
        y: e.pageY - 7,
      });
    }

    document.body.addEventListener("mousemove", onMouseMove);
  },
});


import { defineComponent, ref } from "vue";

const showNav = ref(false);

const testBtn = () => {
  showNav.value = !showNav.value;
  console.log(showNav.value);
};

export default defineComponent({
  name: "MobileNavigation",
  components: {},
  emits: ['handleRouteChange'],
  setup() {
    return {
      testBtn,
      showNav,
    };
  },
  methods: {
    changeRoute(route: string) {
      this.$emit('handleRouteChange', route);
      showNav.value = false;
    },
  },
});

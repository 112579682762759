import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("a", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeRoute('home'))),
      onKeypress: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeRoute('home'))),
      class: "anim text-white font-extralight text-xl pointer-events-auto"
    }, "home", 32),
    _createElementVNode("a", {
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeRoute('portfolio'))),
      onKeypress: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeRoute('portfolio'))),
      class: "anim text-white font-extralight text-xl pointer-events-auto"
    }, "portfolio", 32),
    _createElementVNode("a", {
      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changeRoute('resume'))),
      onKeypress: _cache[5] || (_cache[5] = ($event: any) => (_ctx.changeRoute('resume'))),
      class: "anim text-white font-extralight text-xl pointer-events-auto"
    }, "resume", 32),
    _createElementVNode("a", {
      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.changeRoute('contact'))),
      onKeypress: _cache[7] || (_cache[7] = ($event: any) => (_ctx.changeRoute('contact'))),
      class: "anim text-white font-extralight text-xl pointer-events-auto"
    }, "contact", 32),
    _createElementVNode("a", {
      onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.changeRoute('about'))),
      onKeypress: _cache[9] || (_cache[9] = ($event: any) => (_ctx.changeRoute('about'))),
      class: "anim text-white font-extralight text-xl pointer-events-auto"
    }, "about", 32)
  ], 64))
}